import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {List, Tabs, Typography} from "antd";
import {DownCircleTwoTone, UpCircleTwoTone} from '@ant-design/icons';
import styled from "styled-components";
import {Link} from 'gatsby';

import Layout from "../../components/layout";
import {getActiveStocks, getAdvancesDeclines} from "../../api";
import {generateRoute, ROUTES} from '../../common';
import SEO from '../../components/SEO';
import toPercent from '../../utils/toPercent';
import approximate from '../../utils/approximate';

const {TabPane} = Tabs;

const TabsContainer = styled.div`
  margin: 0 10px;
  height: 100%;
  
  .ant-tabs { 
    height: 100%;
  }
  
  .ant-tabs-nav-scroll > .ant-tabs-nav {
    margin-left: 20px;
  }
  
  .ant-tabs-content-holder {
    overflow-y: auto;
  }
`;

const DescriptionContainer = styled.div`
  flex: 1;
`;

const PriceActionsContainer = styled.div`
  display: flex;
  flex-direction: row;

  .price-action {
    display: flex;
    flex-direction: column;
    margin-left: 30px;

    .value {
      font-size: 1.2em;
      font-weight: bold;
    }

    .label {
      font-size: 0.9em;
    }
  }
`;

const StockCardContainer = styled.div`
  margin: 0 15px 15px 15px;
  border: lightgray 1px solid;
  border-radius: 4px;
  width: 100%;

  header {
    padding: 15px 25px;
    border-bottom: lightgray 1px solid;

    display: flex;
    flex-direction: row;
  }
`;

const ChartContainer = styled.div`
  overflow: hidden;
`;

const PRICE_MOVE_DIRECTION = {
    UP: "up",
    DOWN: "down",
    NONE: "none"
};

function getPriceMoveDirection(stock_data) {
    if (!stock_data.history.length) {
        return PRICE_MOVE_DIRECTION.NONE;
    }

    if (stock_data.history.length === 1) {
        return PRICE_MOVE_DIRECTION.NONE;
    }

    const {length, [length - 1]: latest, [length - 2]: previousLatest} = stock_data.history;
    // If the latest price history is the same as the stock_data.trading_date, use the previous
    // price history as our "last/latest" data to comparison.
    const isLatestHistorySameDay = latest.trading_date == stock_data.trading_date;
    const last = isLatestHistorySameDay ? previousLatest : latest;

    const {close} = last;

    if (stock_data.close > close) return PRICE_MOVE_DIRECTION.UP;
    else if (stock_data.close < close) return PRICE_MOVE_DIRECTION.DOWN;

    return PRICE_MOVE_DIRECTION.NONE;
}

const CaretIconContainer = styled.span`
  .anticon {
    margin-right: 12px;
  }
`;

function getCaretIcon(priceMoveDirection) {
    if (priceMoveDirection === PRICE_MOVE_DIRECTION.NONE) return null;

    const icon = priceMoveDirection == PRICE_MOVE_DIRECTION.UP ? <UpCircleTwoTone twoToneColor="#2EA397"/> :
        <DownCircleTwoTone twoToneColor="#EE514F"/>;

    return <CaretIconContainer>{icon}</CaretIconContainer>;
}

function StockCard({stock_data}) {
    const chartContainer = useRef(null);
    const [chartRendered, setChartRendered] = useState(false);

    useEffect(() => {
        if (!chartContainer.current || chartRendered) return;

        const chart = LightweightCharts.createChart(chartContainer.current, {
            width: chartContainer.current.clientWidth,
            height: 150,
            priceScale: {
                borderVisible: false
            },
            timeScale: {
                fixLeftEdge: true
            },
            layout: {
                backgroundColor: "#131722",
                textColor: "#d1d4dc"
            },
            grid: {
                vertLines: {
                    color: "rgba(42, 46, 57, 0)"
                },
                horzLines: {
                    color: "rgba(42, 46, 57, 0.6)"
                }
            }
        });
        const series = chart.addCandlestickSeries();
        series.setData(stock_data.history);

        setChartRendered(true);
    }, [chartContainer, chartRendered, stock_data.history]);

    let priceMoveDirection = getPriceMoveDirection(stock_data);

    const stockPageRoute = generateRoute(ROUTES.STOCKS, {stock_code: stock_data.stock_code});

    return (
        <StockCardContainer>
            <header>
                <DescriptionContainer>
                    <Typography.Title level={4}>
                        {getCaretIcon(priceMoveDirection)}
                        <Link to={stockPageRoute}>{stock_data.stock_code}</Link>
                    </Typography.Title>
                </DescriptionContainer>
                <PriceActionsContainer>
                    <div className="price-action">
                        <span className="value">{toPercent(stock_data.percent_change)}</span>
                        <span className="label">% Change</span>
                    </div>
                    <div className="price-action">
                        <span className="value">{stock_data.close}</span>
                        <span className="label">Last</span>
                    </div>
                    <div className="price-action">
                        <span className="value">{approximate(stock_data.volume)}</span>
                        <span className="label">Volume</span>
                    </div>
                    <div className="price-action">
                        <span className="value">{approximate(stock_data.value)}</span>
                        <span className="label">Value</span>
                    </div>
                </PriceActionsContainer>
            </header>
            <ChartContainer>
                <div ref={chartContainer}></div>
            </ChartContainer>
        </StockCardContainer>
    );
}

StockCard.propTypes = {
    stock_data: PropTypes.shape({
        stock_code: PropTypes.string,
        percent_change: PropTypes.number,
        close: PropTypes.number,
        volume: PropTypes.number,
        value: PropTypes.number,
        history: PropTypes.array
    })
};

function MostActive() {
    const [activeStocks, setActiveStocks] = useState([]);
    const [advancesDeclines, setAdvancesDeclines] = useState([]);
    const [isFetchingActiveStocks, setIsFetchingActiveStocks] = useState(false);
    const [isFetchingAdvancesDeclines, setIsFetchingAdvancesDeclines] = useState(false);

    useEffect(() => {
        (async function () {
            setIsFetchingActiveStocks(true);
            const activeStocks = await getActiveStocks();
            setActiveStocks(activeStocks);
            setIsFetchingActiveStocks(false);
        })();
    }, []);

    useEffect(() => {
        (async function () {
            setIsFetchingAdvancesDeclines(true);
            const advancesDeclines = await getAdvancesDeclines();
            setAdvancesDeclines(advancesDeclines);
            setIsFetchingAdvancesDeclines(false);
        })();
    }, []);

    return (
        <Layout>
            <SEO title="Most Active" description="See what stocks are most active, advanced and declined the most."/>
            <TabsContainer>
                <Tabs defaultActiveKey="1" animated={false}>
                    <TabPane tab="Most Active" key="1">
                        <List
                            dataSource={activeStocks}
                            loading={isFetchingActiveStocks}
                            renderItem={item => (
                                <List.Item>
                                    <StockCard key={`${item.stock_code}`} stock_data={item}/>
                                </List.Item>
                            )}
                        />
                    </TabPane>
                    <TabPane tab="Advances" key="2">
                        <List
                            dataSource={advancesDeclines.advances}
                            loading={isFetchingAdvancesDeclines}
                            renderItem={item => (
                                <List.Item>
                                    <StockCard key={`${item.stock_code}`} stock_data={item}/>
                                </List.Item>
                            )}
                        />
                    </TabPane>
                    <TabPane tab="Declines" key="3">
                        <List
                            dataSource={advancesDeclines.declines}
                            loading={isFetchingAdvancesDeclines}
                            renderItem={item => (
                                <List.Item>
                                    <StockCard key={`${item.stock_code}`} stock_data={item}/>
                                </List.Item>
                            )}
                        />
                    </TabPane>
                </Tabs>
            </TabsContainer>
        </Layout>
    );
}

export default MostActive;
